@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
    url('../assets/Fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url('../assets/Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-MediumItalic';
  src: local('Poppins-MediumItalic'),
    url('../assets/Fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}
