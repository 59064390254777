/* HelpCentreQA.css */

.pageContainer {
  overflow-x: hidden;
}

.logoContainer {
  display: flex;
  justify-content: center;
  background-color: #ffd4ac;
  padding: 20px;
  max-width: 100%;
  height: 120px;
}

.logo {
  max-width: 100%;
  object-fit: contain;
}

.outerContainer {
  background-color: #ffd4ac;
  padding: 20px 180px;
  font-family: "Poppins, sans-serif" !important;
}

.innerContainer {
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
}

.headingFaq {
  font-weight: 500 !important;
  font-size: 26px !important;
  text-decoration: underline;
  color: #8a4504;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  margin: 30px 0 !important;
  text-decoration-skip-ink: none;
}

.spanFaq {
  font-weight: 700 !important;
  font-size: 26px !important;
  font-family: 'Poppins', sans-serif !important;
}

.contentFaq {
  font-size: 18px !important;
  font-family: 'Poppins', sans-serif !important;
  margin: 30px 0 !important;
}

.instructionsHeadingFaq {
  border: 4px solid #8a4504;
  border-radius: 14px;
  padding: 24px;
  font-family: 'Poppins', sans-serif !important;
  margin: 30px 0 !important;
}

.instructionsHeading {
  font-size: 30px !important;
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important;
  margin-bottom: 28px !important;
}

.instructionsSubheading {
  font-size: 20px !important;
  font-family: 'Poppins', sans-serif !important;
}

.contentContainer {
  padding: 20px;
}

.bottomContent {
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  padding: 30px;
}

.hyperlinks {
  font-family: 'Poppins', sans-serif !important;
  color: black;
  text-decoration: none;
}

/* Media query for screens less than 900px */
@media (max-width: 900px) {
  .outerContainer {
    padding: 20px 50px;
    /* Adjust the padding for smaller screens */
  }
}

/* Media query for screens less than 600px */
@media (max-width: 600px) {
  .outerContainer {
    padding: 20px 30px;
    /* Adjust the padding for smaller screens */
  }
}

.guideHypertext {
  color: #FF0000;
  text-decoration: none;
}