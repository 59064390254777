.progress-0>.MuiLinearProgress-bar1Determinate {
    background-color: purple !important;
}

.progress-1>.MuiLinearProgress-bar1Determinate {
    background-color: yellow !important;
}

.progress-2>.MuiLinearProgress-bar1Determinate {
    background-color: rgb(182, 82, 98) !important;
}

.progress-3>.MuiLinearProgress-bar1Determinate {
    background-color: green !important;
}

.pageContainer-DB {
    overflow-x: hidden;
}

.logoContainer-DB {
    display: flex;
    background-color: #ffd4ac;
    justify-content: space-between;
    padding: 20px;
    max-width: 100%;
    height: 120px;
}

.logo-DB {
    max-width: 100%;
    object-fit: contain;
    width: 100%;
}

.outerContainer-DB {
    background-color: #ffd4ac;
    padding: 20px 180px;
    font-family: 'Poppins-Medium', sans-serif !important;
}

.innerContainer-DB {
    background-color: white;
    border-radius: 20px;
    box-shadow: 5px 10px 8px #888888;
    margin-bottom: 5% !important;
}

.contentContainer-DB {
    margin-top: 7%;
    margin-bottom: 7%;
    margin-left: 9%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.row-DB {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 31px;
}

/* Common styles for the coloredBox */
.coloredBox {
    background-color: #FFEFDF;
    width: 25%;
    height: 180px;
    display: flex;
    border-radius: 16px;
    flex-direction: column;
    /* Adjust to stack content vertically */
    align-items: center;
    margin-right: 40px !important;
    margin-bottom: 50px !important;
    justify-content: center;
}

/* Styles for the Box-data inside coloredBox */
.Box-data {
    text-align: center;
    cursor:pointer
}

/* Style for the icon */
.Box-data img {
    width: 50px;
    height: 50px;
}


/* Responsive styles for mobile devices */
@media (max-width: 767px) {
    .coloredBox {
        width: calc(50% - 30px);
        margin-right: 15px;
        margin-bottom: 15px;
    }
}

.box-label-text {
    font-family: 'Poppins-Medium', sans-serif !important;
    font-weight: 500;
    font-size: 22px;
    color: #8A4504;
}

/* Responsive styles for smaller screens (laptop with lower resolution) */
@media (max-width: 1366px) {
    .box-label-text {
        font-size: 16px;
        /* Adjust the font size for smaller screens */
    }
}

/* Responsive styles for even smaller screens (laptop with lower resolution) */
@media (max-width: 1280px) {
    .box-label-text {
        font-size: 14px;
        /* Adjust the font size for even smaller screens */
    }
}

/* Responsive styles for mobile devices */
@media (max-width: 767px) {
    .box-label-text {
        font-size: 12px;
        /* Adjust the font size for mobile devices */
    }
}

/* Responsive styles for smaller screens (laptop with lower resolution) */
@media (max-width: 1366px) {
    .Box-data img {
        width: 30px;
        /* Adjust the icon size for smaller screens */
        height: 30px;
    }
}

/* Responsive styles for even smaller screens (laptop with lower resolution) */
@media (max-width: 1280px) {
    .Box-data img {
        width: 20px;
        /* Adjust the icon size for even smaller screens */
        height: 20px;
    }
}

/* Responsive styles for mobile devices */
@media (max-width: 767px) {
    .Box-data {
        padding: 10px;
        /* Add some padding for better spacing */
    }

    .Box-data img {
        width: 15px;
        /* Adjust the icon size for mobile devices */
        height: 15px;
    }
}
.topmargin{
    margin-top:50px
}

